import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import { IconButton, TableCell } from '@mui/material';
import { OverflownText } from 'Components/components.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import { getFullTableName, highlightText } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as ArrowRightIcon } from 'assets/img/icons/arrows/arrow-right.svg';
import { ReactComponent as LinkIcon } from 'assets/img/icons/linkIcon.svg';

const useStyles = makeStyles(() => ({
  rotateIcon: {
    transform: (props) => (props.isOpen ? 'rotate(90deg)' : ''),
    transition: 'transform 300ms ease',
  },
}));

const DestinationTableCell = ({
  isOpen,
  tableInfo,
  searchTable = null,
  searchValue = '',
  icon = null,
}) => {
  const tableName = getFullTableName(tableInfo?.table, tableInfo?.dataset);
  const { DESTINATION_TABLE_NAME_WIDTH } = PipelineTableCellStyles;
  const classes = useStyles({ isOpen });

  const path = tableName.includes('.')
    ? {
        pathname: AppRoutes.Monitors.path,
        search: new URLSearchParams({
          dataset: tableInfo.dataset,
          table: tableInfo.table,
        }).toString(),
      }
    : null;

  return (
    <TableCell className='cellWithIcon'>
      <IconButton sx={{ width: 20 }} size='small'>
        <ArrowRightIcon className={classes.rotateIcon} />
      </IconButton>
      <div>
        <div className='txt-grey-10-500 uppercase'>Destination table</div>
        <div className='txt-mainDark-13-700 cellContentWithIcon'>
          {icon && <Image src={icon} height={13} />}

          <OverflownText
            title={tableName}
            maxWidth={DESTINATION_TABLE_NAME_WIDTH}
          >
            {highlightText(tableName, searchTable, searchValue)}
          </OverflownText>

          {path && (
            <Link to={path} className='d-flex'>
              <LinkIcon width={14} />
            </Link>
          )}
        </div>
      </div>
    </TableCell>
  );
};

export { DestinationTableCell };
