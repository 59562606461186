const NodeTypes = {
  DATASET: 'DATASET',
  LOOKER_LOOK: 'LOOKER_LOOK',
  LOOKER_DASHBOARD_ELEMENT: 'LOOKER_DASHBOARD_ELEMENT',
  SOURCE_URI: 'SOURCE_URI',
  DESTINATION_URI: 'DESTINATION_URI',
  SERVICE_ACCOUNT: 'SERVICE_ACCOUNT',
  PIPELINE: 'PIPELINE',
  ROUTINE: 'ROUTINE',
};

export { NodeTypes };
