import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  useEffect,
  useState,
  useHistory,
  useLocation,
  useGetTimezoneValues,
} from 'hooks/hooks.js';
import { AutocompleteSelect, Button } from 'Components/components.js';
import { useSignUpInfo, useUserInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { fetcherPost } from 'utils/utils.js';
import { getProjectNumber } from 'utils/helpers/helpers.js';
import { AUTH_ERRORS, SEARCH_PARAMS } from 'constants/constants.js';
import { auth, gcpCreator, signUpAmplEvent } from 'service/services.js';
import {
  ProjectAutocomplete,
  SetUpProject,
  SetUpLoading,
} from './components/components.js';
import { INPUT_VALUE_ERRORS } from './libs/constants/constants.js';

const TRACKER_EVENT = 'Deployment (Choose GCP Project)';
const EVENT_BUTTON_CONNECT = `${TRACKER_EVENT} -> Click - Connect`;

const CreateFormComponent = ({
  project,
  onChangeProject,
  timezones,
  timezoneValue,
  onChangeTimezoneValue,
  signUpInfo,
  onConnectClick,
}) => {
  return (
    <div className='form'>
      <div className='formBlock'>
        <AutocompleteSelect
          value={timezoneValue}
          onChange={onChangeTimezoneValue}
          options={timezones}
          label='What timezone are you using to schedule your data pipelines? If not sure, select default (UTC).'
          id='timeZone'
        />

        <ProjectAutocomplete
          signUpInfo={signUpInfo}
          onChangeProject={onChangeProject}
        />
      </div>

      <Button
        text='Connect'
        className='formButton'
        isDisabled={!project}
        onClick={onConnectClick}
      />
    </div>
  );
};

const CreateTextBlockComponent = () => {
  return (
    <div>
      Choose the Google Cloud project you wish to observe. Masthead will
      automatically
      <br />
      create all the necessary resources within your selected project to monitor
      all your
      <br />
      data assets and pipelines.
    </div>
  );
};

const DeploymentChooseProject = () => {
  const { signUpInfo, setSignUpInfo, setSignUpError } = useSignUpInfo();
  const { setUser, setAuthError } = useUserInfo();
  const history = useHistory();
  const location = useLocation();
  const { timezones, defaultUTCTimezone } = useGetTimezoneValues();

  const [loading, setLoading] = useState(false);
  const [projectValue, setProjectValue] = useState(null);
  const [timezoneValue, setTimezoneValue] = useState(defaultUTCTimezone);
  const [error, setError] = useState(null);

  useEffect(
    () =>
      signUpAmplEvent(
        `${TRACKER_EVENT}`,
        signUpInfo?.accessToken,
        signUpInfo?.name,
        signUpInfo?.company,
        signUpInfo?.companyUseCase
      ),
    [signUpInfo]
  );

  const handleChangeProject = (value) => {
    setProjectValue(value);
    setError(null);
  };

  const handleChangeTimezoneValue = (value) => {
    setTimezoneValue(value);
  };

  const token = signUpInfo?.token;

  if (!signUpInfo) {
    const searchParams = new URLSearchParams(location.search);
    const marketplaceId = searchParams.get('marketplaceId');

    const queryString = marketplaceId ? `?marketplaceId=${marketplaceId}` : '';
    return <Redirect to={`${AppRoutes.SignUp.path}${queryString}`} />;
  }

  const saveValue = async () => {
    if (!projectValue) {
      setError(INPUT_VALUE_ERRORS.chooseGcp);
      return;
    }
    setLoading(true);
    const projectNumber = getProjectNumber(projectValue);
    const projectId = projectValue.projectId;

    const request = {
      adminEmail: signUpInfo.user.email,
      project: projectId,
      projectNumber: projectNumber,
      name: signUpInfo.name,
      company: signUpInfo.company,
      companyRole: signUpInfo.companyRole,
      companyUseCase: signUpInfo.companyUseCase,
      marketplaceAccountId: signUpInfo.marketplaceAccountId,
      timezone: signUpInfo.timezone,
    };
    try {
      const validateData = await fetcherPost('/validate/sign-up', request);
      if (validateData?.success) {
        signUpAmplEvent(
          `${EVENT_BUTTON_CONNECT} Validate (success)`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      } else {
        let errorMessage = AUTH_ERRORS.STH_ELSE;
        if (validateData?.error) {
          errorMessage = validateData?.error;
        }
        setError(errorMessage);
        signUpAmplEvent(
          `${EVENT_BUTTON_CONNECT} Validate (error_1 - ${errorMessage})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );

        setLoading(false);
        setProjectValue('');
        return;
      }
    } catch (e) {
      setError(AUTH_ERRORS.STH_ELSE);
      signUpAmplEvent(
        `${EVENT_BUTTON_CONNECT} Validate (error_2 - ${e.message})`,
        signUpInfo.accessToken,
        signUpInfo.name,
        signUpInfo.company,
        signUpInfo.companyUseCase
      );

      setLoading(false);
      setProjectValue('');
      return;
    }

    signUpInfo.project = projectId;
    signUpInfo.timezone = timezoneValue?.value || defaultUTCTimezone.value;

    gcpCreator
      .create(signUpInfo.project, token, projectNumber)
      .then(() => {
        fetcherPost('/sign-up', request)
          .then((data) => {
            if (data?.value) {
              setLoading(false);
              const user = signUpInfo.user;
              user.info = data.value;
              setUser(user);
              signUpAmplEvent(
                `${EVENT_BUTTON_CONNECT} (success)`,
                signUpInfo.accessToken,
                signUpInfo.name,
                signUpInfo.company,
                signUpInfo.companyUseCase
              );
              auth.updateCurrentUser(user).then();
              history.push(
                `${AppRoutes.Dashboard.path}?${SEARCH_PARAMS.CREATE_PROJECT}=success`
              );
            } else {
              setSignUpError(AUTH_ERRORS.STH_ELSE);
              signUpAmplEvent(
                `${EVENT_BUTTON_CONNECT} (error_1)`,
                signUpInfo.accessToken,
                signUpInfo.name,
                signUpInfo.company,
                signUpInfo.companyUseCase
              );
            }
          })
          .catch((e) => {
            setSignUpError(AUTH_ERRORS.STH_ELSE);
            signUpAmplEvent(
              `${EVENT_BUTTON_CONNECT} (error_2 - ${e.message})`,
              signUpInfo.accessToken,
              signUpInfo.name,
              signUpInfo.company,
              signUpInfo.companyUseCase
            );
          })
          .finally(() => {
            setLoading(false);
            setSignUpInfo(null);
          });
      })
      .catch((e) => {
        setSignUpError(e.message);
        setLoading(false);
        setSignUpInfo(null);
        setAuthError(null);
        signUpAmplEvent(
          `${EVENT_BUTTON_CONNECT} (error_3 - ${e.message})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      });
  };

  if (loading) {
    return <SetUpLoading />;
  }

  return (
    <SetUpProject
      error={error}
      title='Select Google Cloud Project '
      FormComponent={CreateFormComponent}
      TextBlockComponent={CreateTextBlockComponent}
      project={projectValue}
      onChangeProject={handleChangeProject}
      timezones={timezones}
      timezoneValue={timezoneValue}
      onChangeTimezoneValue={handleChangeTimezoneValue}
      signUpInfo={signUpInfo}
      onConnectClick={saveValue}
    />
  );
};

export default DeploymentChooseProject;
