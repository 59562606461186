import { TABLE_TYPES, ROUTINE_TYPE } from 'constants/constants.js';
import { ReactComponent as ExternalIcon } from 'assets/img/icons/lineageIcons/node-icons/external-table.svg';
import { ReactComponent as ViewIcon } from 'assets/img/icons/lineageIcons/node-icons/view.svg';
import { ReactComponent as DbTableIcon } from 'assets/img/icons/lineageIcons/node-icons/table.svg';
import { ReactComponent as RoutineIcon } from 'assets/img/icons/lineageIcons/node-icons/routine.svg';

export const REFERENCED_TYPE = {};

REFERENCED_TYPE[TABLE_TYPES.external] = {
  icon: ExternalIcon,
};
REFERENCED_TYPE[TABLE_TYPES.view] = {
  icon: ViewIcon,
};
REFERENCED_TYPE[TABLE_TYPES.dbTable] = {
  icon: DbTableIcon,
};
REFERENCED_TYPE[ROUTINE_TYPE.routine] = {
  icon: RoutineIcon,
};
