const NodeTypeNames = {
  TABLE: 'Table',
  VIEW: 'View',
  EXTERNAL: 'External',
  LOOKER_DASHBOARD_ELEMENT: 'Looker dashboard element',
  LOOKER_LOOK: 'Looker look',
  SOURCE_URI: 'Source URI',
  DESTINATION_URI: 'Destination URI',
  SERVICE_ACCOUNT: 'Service account',
  PIPELINE: 'Pipeline',
  ROUTINE: 'Stored procedure',
};

export { NodeTypeNames };
