import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import { EmptySearchData } from 'Pages/Pipelines/components/components.js';
import { ResourceTableHead } from './ResourceTableHead.js';
import { ResourceTableRows } from './ResourceTableRows.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { DEFAULT_SEARCH_MESSAGE } from 'Pages/Pipelines/libs/constants/constants.js';

const ResourceTable = ({
  destinationTables = [],
  isFetchingDestinationTables,
  startDate,
  endDate,
  selectedTag,
}) => {
  const classes = useStyles();
  const isEmptySearch =
    selectedTag.length &&
    !destinationTables.length &&
    !isFetchingDestinationTables;

  return (
    <TableContainer sx={{ padding: 0 }}>
      <Table>
        <ResourceTableHead />
        <TableBody className={classes.tableBody}>
          {isEmptySearch ? (
            <EmptySearchData
              text={`${DEFAULT_SEARCH_MESSAGE} label/tag`}
              colSpan={7}
            />
          ) : (
            destinationTables.length > 0 &&
            destinationTables.map((destinationTable) => (
              <ResourceTableRows
                key={`${getFullTableName(
                  destinationTable.table,
                  destinationTable.dataset
                )}/${destinationTable.pipelineType}`}
                destinationTable={destinationTable}
                startDate={startDate}
                endDate={endDate}
                selectedTag={selectedTag}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ResourceTable };
