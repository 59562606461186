import React from 'react';
import { useState, useEffect, useCallback } from 'hooks/hooks.js';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Alert, useAutocompleteStyles } from 'Components/components.js';
import { signUpAmplEvent } from 'service/services.js';
import { AUTH_ERRORS } from 'constants/constants.js';
import { ReactComponent as ArrowDown } from 'assets/img/icons/sidebarIcons/arrowDown.svg';

const INPUT_VARIANT = 'standard';
const TRACKER_EVENT = 'Deployment (Choose GCP Project)';
const PAGE_SIZE = 1000;

const ProjectAutocomplete = ({ signUpInfo, onChangeProject }) => {
  const classes = useAutocompleteStyles();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [nextPageToken, setNextPageToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [error, setError] = useState(null);

  const fetchProjects = useCallback(
    async (searchQuery = '', pageToken = null) => {
      setLoading(true);

      try {
        let url = `https://cloudresourcemanager.googleapis.com/v3/projects:search?pageSize=${PAGE_SIZE}`;
        if (pageToken) {
          url += `&pageToken=${pageToken}`;
        }
        if (searchQuery && !selectedProject) {
          url += `&query=id:"*${searchQuery}*" OR name:"*${searchQuery}*"`;
        }
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${signUpInfo.token}`,
          },
        });

        if (!response.ok) {
          setError(AUTH_ERRORS.PROJECTS_NOT_FOUND);
          signUpAmplEvent(
            `${TRACKER_EVENT} => error: ${AUTH_ERRORS.PROJECTS_NOT_FOUND}`,
            signUpInfo.accessToken,
            signUpInfo.name,
            signUpInfo.company,
            signUpInfo.companyUseCase
          );
        }

        const data = await response.json();

        setProjects((prevProjects) =>
          pageToken ? [...prevProjects, ...data.projects] : data.projects || []
        );
        setNextPageToken(data.nextPageToken || null);
      } catch (error) {
        setError(AUTH_ERRORS.STH_ELSE);
        signUpAmplEvent(
          `${TRACKER_EVENT} (error: ${error.message})`,
          signUpInfo?.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      } finally {
        setLoading(false);
      }
    },
    [selectedProject, signUpInfo]
  );

  useEffect(() => {
    if (open) {
      fetchProjects(query);
    }
  }, [fetchProjects, query, open]);

  const handleChangeSelectedProject = (_, newValue) => {
    setSelectedProject(newValue);
    setQuery('');

    onChangeProject(newValue);
  };

  const handleInputChange = (_, value) => {
    setQuery(value);
    setNextPageToken(null);
    setProjects([]);
  };

  const clearSelectedProject = () => {
    setSelectedProject(null);
    onChangeProject(null);
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;

    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
      listboxNode.scrollHeight - 1
    ) {
      if (nextPageToken && !loading) {
        fetchProjects(query, nextPageToken);
      }
    }
  };

  return (
    <>
      <FormControl className={classes.formControl} variant={INPUT_VARIANT}>
        <Autocomplete
          disablePortal
          value={selectedProject}
          onChange={handleChangeSelectedProject}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onInputChange={handleInputChange}
          options={projects}
          getOptionLabel={(option) =>
            `${option.displayName} (ID: ${option.projectId})`
          }
          renderOption={(props, option) => {
            return (
              <li {...props}>
                {option.displayName}{' '}
                <span className='txt-grey-13-500'>
                  (ID: {option.projectId})
                </span>
              </li>
            );
          }}
          loading={loading}
          ListboxProps={{
            onScroll: handleScroll,
            style: { maxHeight: '300px', overflow: 'auto' },
          }}
          isOptionEqualToValue={(option, value) => {
            if (!value.projectId || !option.projectId) {
              return false;
            }
            return option.projectId === value.projectId;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label='GCP Project ID'
              placeholder='Start typing a project name or ID'
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          popupIcon={<ArrowDown />}
          onKeyDown={() => clearSelectedProject()}
          clearIcon={null}
        />
      </FormControl>

      <Alert alertComponent={error} />
    </>
  );
};

export { ProjectAutocomplete };
