import { SEARCH_PARAMS } from 'constants/constants.js';

const DEFAULT_SEARCH_MESSAGE = 'No incidents found associated to this';

const SEARCH_PARAMS_CONFIG = [
  SEARCH_PARAMS.TABLE,
  SEARCH_PARAMS.DATASET,
  SEARCH_PARAMS.SEARCH,
];

const PARAM_LABELS = {
  [SEARCH_PARAMS.TABLE]: 'table',
  [SEARCH_PARAMS.DATASET]: 'dataset',
  [SEARCH_PARAMS.SEARCH]: 'search attribute',
};

const EMPTY_SEARCH_MESSAGES = SEARCH_PARAMS_CONFIG.reduce((acc, param) => {
  acc[param] = `${DEFAULT_SEARCH_MESSAGE} ${PARAM_LABELS[param]}`;
  return acc;
}, {});

export { SEARCH_PARAMS_CONFIG, EMPTY_SEARCH_MESSAGES, DEFAULT_SEARCH_MESSAGE };
