import { NodeTypeNames, NodeTypes } from '../../enums/enums.js';
import { getDatasetTypeName } from './helpers.js';

const getNodeTypeName = (nodeType, datasetType) => {
  switch (nodeType) {
    case NodeTypes.DATASET:
      return getDatasetTypeName(datasetType);
    case NodeTypes.LOOKER_DASHBOARD_ELEMENT:
      return NodeTypeNames.LOOKER_DASHBOARD_ELEMENT;
    case NodeTypes.LOOKER_LOOK:
      return NodeTypeNames.LOOKER_LOOK;
    case NodeTypes.SOURCE_URI:
      return NodeTypeNames.SOURCE_URI;
    case NodeTypes.DESTINATION_URI:
      return NodeTypeNames.DESTINATION_URI;
    case NodeTypes.SERVICE_ACCOUNT:
      return NodeTypeNames.SERVICE_ACCOUNT;
    case NodeTypes.PIPELINE:
      return NodeTypeNames.PIPELINE;
    case NodeTypes.ROUTINE:
      return NodeTypeNames.ROUTINE;
    default:
      return '';
  }
};

export { getNodeTypeName };
