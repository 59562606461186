import { useRoutineDetails } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { PositionLoader, QueryWidget } from 'Components/components.js';
import { RoutineInfoWidget } from './components/components.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
}));

const RoutineDetails = ({ project, dataset, routineName }) => {
  const classes = useStyles();
  const { routineDetails, isFetchingRoutineDetails } = useRoutineDetails({
    project,
    dataset,
    name: routineName,
  });

  if (isFetchingRoutineDetails) {
    return <PositionLoader />;
  }

  return (
    <div>
      {routineDetails ? (
        <div className={classes.container}>
          <RoutineInfoWidget routineDetails={routineDetails} />
          <QueryWidget
            query={routineDetails.body}
            title='Stored procedure query'
          />
        </div>
      ) : null}
    </div>
  );
};

export { RoutineDetails };
