import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  chip: {
    '&.MuiChip-root': {
      maxWidth: (props) =>
        props.tooltipTitle.length ? props.maxWidth : 'max-content',
      background: '#EEE',
      border: '1px solid rgba(5, 12, 46, 0.10)',
      borderRadius: '100px',

      '&.MuiChip-clickable': {
        '&:hover': {
          backgroundColor: '#EAEAEA',
        },
      },

      '&.MuiChip-colorPrimary': {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,

        '&.MuiChip-clickable': {
          '&:hover': {},
        },
      },

      '&.MuiChip-colorSecondary': {
        color: theme.palette.text.primary,
        background: '#FFEEAE',

        '&.MuiChip-clickable': {
          '&:hover': {
            backgroundColor: '#F7E6A9',
          },
        },
      },

      '&.MuiChip-colorSuccess': {},

      '&.MuiChip-colorWarning': {
        color: theme.palette.text.primary,
        background: '#FEFFD0',

        '&.MuiChip-clickable': {
          '&:hover': {
            backgroundColor: '#F9F9CA',
          },
        },
      },

      '&.MuiChip-colorError': {
        color: theme.palette.text.primary,
        background: '#FEE8E8',
      },

      '&.MuiChip-colorInfo': {
        color: theme.palette.text.primary,
        background: theme.palette.secondary.main,

        '&.MuiChip-clickable': {
          '&:hover': {
            backgroundColor: '#E0EBFF',
          },
        },
      },

      '&.MuiChip-sizeSmall': {
        height: 26,
        padding: '2px 8px',
        fontSize: theme.typography.fontSize,
        lineHeight: theme.typography.lineHeight,
      },

      '& .MuiChip-deleteIcon': {
        marginLeft: theme.spacing(1),
      },

      '& .MuiChip-icon': {
        height: 14,
        marginRight: theme.spacing(1),
      },

      '& .MuiChip-label': {
        padding: 0,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
}));
