import { useState, useEffect, useMemo, useQueries } from 'hooks/hooks.js';
import { NodeTypes } from 'Components/GraphPipes/enums/enums.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import { PIPELINE_TYPE_DATA } from 'utils/constants.js';
import { QUERY_TYPES, ROUTINE_TYPE, TABLE_TYPES } from 'constants/constants.js';

const PIPELINE_LABEL = 'Pipeline';

const usePipelineLineageData = (
  destinationTable,
  destinationUri,
  sourceUri,
  sourceTables,
  pipeline
) => {
  const [tableInfoByTables, setTableInfoByTables] = useState(null);
  const sourceUriData = sourceUri !== null && new Array(sourceUri);
  const sourceData =
    !sourceTables.length && !!sourceUriData ? sourceUriData : sourceTables;

  const lineageData = useMemo(() => {
    const destinationLabel = destinationUri
      ? destinationUri
      : getFullTableName(destinationTable.table, destinationTable.dataset);
    const destinationId = destinationUri
      ? destinationLabel
      : `${destinationTable.project}.${destinationLabel}`;
    const pipelineLabel = PIPELINE_TYPE_DATA[pipeline.pipelineType || 0]?.title;
    const isPipelineNodeDestination =
      pipelineLabel.toUpperCase() === destinationLabel.toUpperCase();

    const sourceNodes = sourceData.map((sourceTable) => {
      const isRoutineNode = sourceTable?.type === ROUTINE_TYPE.routine;
      const isTable = sourceTable.name && sourceTable.dataset && !isRoutineNode;
      const nodeType = isRoutineNode
        ? NodeTypes.ROUTINE
        : isTable
        ? NodeTypes.DATASET
        : NodeTypes.SOURCE_URI;

      const sourceLabel =
        isTable || isRoutineNode
          ? getFullTableName(sourceTable.name, sourceTable.dataset)
          : sourceTable;
      const sourceId = isTable
        ? `${sourceTable.project}.${sourceLabel}`
        : sourceLabel;

      let attrsNumber = 0;
      let tableType = TABLE_TYPES.dbTable;
      let alertType = null;
      if (tableInfoByTables && tableInfoByTables[sourceId]) {
        attrsNumber = tableInfoByTables[sourceId].columnsNumber;
        tableType = tableInfoByTables[sourceId].tableType;
        alertType = tableInfoByTables[sourceId].alertType;
      }
      return {
        id: sourceId,
        label: sourceLabel,
        nodeType,
        nodeTypeData: isTable
          ? {
              attrs: [],
              attrsNumber: attrsNumber,
              datasetType: tableType,
              alertType,
            }
          : {},
        project: sourceTable.project || '',
        upEdges: [],
        downEdges: [],
      };
    });
    const pipelineNode = {
      id: PIPELINE_LABEL,
      label: pipelineLabel,
      nodeType: NodeTypes.PIPELINE,
      nodeTypeData: {
        pipelineType: pipeline.pipelineType || 0,
      },
      project: pipeline.sourceProject || '',
      upEdges: sourceNodes.map((it) => ({
        keys: { '': '' },
        source: it.id,
        target: PIPELINE_LABEL,
        targetNodeType: it.nodeType,
      })),
      downEdges: [],
      destinationNode: isPipelineNodeDestination,
    };
    let destinationAttrsNumber = 0;
    let destinationTableType = TABLE_TYPES.dbTable;
    if (tableInfoByTables && tableInfoByTables[destinationId]) {
      destinationAttrsNumber = tableInfoByTables[destinationId].columnsNumber;
      destinationTableType = tableInfoByTables[destinationId].tableType;
    }
    const destinationNode = isPipelineNodeDestination
      ? pipelineNode
      : {
          id: destinationId,
          label: destinationLabel,
          nodeType: !destinationUri
            ? NodeTypes.DATASET
            : NodeTypes.DESTINATION_URI,
          nodeTypeData: !destinationUri
            ? {
                attrs: [],
                attrsNumber: destinationAttrsNumber,
                datasetType: destinationTableType,
                alertType: destinationTable.alertType,
              }
            : {},
          project: destinationTable.project,
          upEdges: [
            {
              keys: { '': '' },
              source: PIPELINE_LABEL,
              target: destinationId,
              targetNodeType: NodeTypes.PIPELINE,
            },
          ],
          downEdges: [],
          destinationNode: true,
        };

    return {
      graph: [
        ...sourceNodes,
        destinationNode,
        ...(!isPipelineNodeDestination ? [pipelineNode] : []),
      ],
    };
  }, [sourceTables, destinationTable, tableInfoByTables, pipeline]);

  const queries = sourceTables.map((item) => {
    return {
      queryKey: [QUERY_TYPES.tableInfo, item.project, item.dataset, item.name],
      queryFn: ({ queryKey }) => {
        const [url, project, dataset, table] = queryKey;
        return fetcherGet(url, {
          project,
          dataset,
          table,
        });
      },
      options: {
        enabled: Boolean(
          item.name && item.dataset && tableInfoByTables === null
        ),
      },
    };
  });
  queries.push({
    queryKey: [
      QUERY_TYPES.tableInfo,
      destinationTable.project,
      destinationTable.dataset,
      destinationTable.table,
    ],
    queryFn: ({ queryKey }) => {
      const [url, project, dataset, table] = queryKey;
      return fetcherGet(url, {
        project,
        dataset,
        table,
      });
    },
    options: {
      enabled: Boolean(
        destinationTable.table &&
          destinationTable.dataset &&
          tableInfoByTables === null
      ),
    },
  });

  const queriesResult = useQueries(queries);
  const isFetching = queriesResult.some((query) => query.isFetching);
  const isSuccess = queriesResult.every((query) => query.isSuccess);

  useEffect(() => {
    if (
      !isFetching &&
      queriesResult.length &&
      isSuccess &&
      tableInfoByTables === null
    ) {
      const values = queriesResult.reduce((acc, curr) => {
        if (!curr?.data?.value) {
          return acc;
        }

        const table = curr.data.value.table;
        const dataset = curr.data.value.dataset;
        const project = curr.data.value.project;
        acc[`${project}.${dataset}.${table}`] = {
          columnsNumber: curr.data.value.columnsNumber,
          tableType: curr.data.value.type,
          alertType: curr.data.value.alertType,
        };
        return acc;
      }, {});
      setTableInfoByTables(values);
    }
  }, [isFetching, queriesResult, isSuccess, tableInfoByTables]);

  return { lineageData, isFetching };
};

export { usePipelineLineageData };
