import { useQuery, useMemo } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useRoutineDetails = ({ project, dataset, name }) => {
  const { data, isFetching: isFetchingRoutineDetails } = useQuery(
    [QUERY_TYPES.routineDetails, project, dataset, name],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url, { project, dataset, name });
    },
    { enabled: !!project && !!dataset && !!name }
  );

  const routineDetails = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  return { routineDetails, isFetchingRoutineDetails };
};

export { useRoutineDetails };
