import React from 'react';
import { useEffect, useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { FormControl, FormGroup } from '@mui/material';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider, signUpAmplEvent } from 'service/services.js';
import { useStyles } from '../../Deployment.js';
import { AUTH_ERRORS } from 'constants/constants.js';
import { useSignUpInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import googleLogo from 'assets/img/googleIconBgd.svg';
import HowTo from 'assets/img/howto.svg';

const GoogleDeployment = ({ handleChangeError }) => {
  const classes = useStyles();
  const eventButton = 'Deployment -> Click -> Google Deployment';
  const { setSignUpInfo, signUpError, setSignUpError, signUpInfo } =
    useSignUpInfo();
  const history = useHistory();
  const toNextStep = () => {
    history.push(AppRoutes.DeploymentChooseProject.path);
  };

  useEffect(() => {
    if (signUpError) {
      handleChangeError(signUpError);
      setSignUpError(null);
    }
  }, [signUpError, setSignUpError, handleChangeError]);

  const googleSignUp = () => {
    googleAuthProvider.addScope('https://www.googleapis.com/auth/pubsub');
    googleAuthProvider.addScope(
      'https://www.googleapis.com/auth/service.management'
    );
    googleAuthProvider.addScope(
      'https://www.googleapis.com/auth/logging.admin'
    );
    googleAuthProvider.addScope(
      'https://www.googleapis.com/auth/cloudplatformprojects'
    );
    googleAuthProvider.addScope('https://www.googleapis.com/auth/iam');
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        setSignUpInfo({
          ...signUpInfo,
          user: result.user,
          token: result._tokenResponse.oauthAccessToken,
          accessToken: result.user.accessToken,
        });
        signUpAmplEvent(
          `${eventButton}`,
          result.user.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
        toNextStep();
      })
      .catch((e) => {
        handleChangeError(AUTH_ERRORS.STH_ELSE);
        signUpAmplEvent(
          `${eventButton} (error: ${e.message})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      });
  };

  return (
    <div>
      <div className='txt-mainDark-16-700 mb-3'>
        Authorise through Google Auth 2.0
      </div>

      <div className={clsx(classes.badgeContainer, 'mb-3')}>
        <div className='badgeItem txt-mainDark-13-700'>
          Deployment time: 2 min
        </div>
        <div className='badgeItem txt-mainDark-13-700'>
          Your permission: GCP Owner
        </div>
      </div>

      <div className='txt-mainDark-13-500 mb-4'>
        Deploy the Masthead app with just 2 clicks. Authorize it with one-time
        <br />
        permissions to automatically create the required resources.
      </div>

      <a
        href='https://docs.mastheadata.com/masthead-agent/saas-deployment'
        target='_blank'
        rel='noreferrer'
        className='mb-4'
        style={{ display: 'flex', gap: 6, alignItems: 'center' }}
      >
        <Image src={HowTo} width={16} height={16} />
        <span className='txt-blue-13-500'>Read more</span>
      </a>

      <form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <FormControl>
            <button
              className={clsx(classes.googleBtn, 'btn-my')}
              onClick={googleSignUp}
            >
              <img src={googleLogo} alt='google login' />
              <span>Deployment with Google</span>
            </button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};

export { GoogleDeployment };
