import { makeStyles, styled } from '@mui/styles';
import { TableCell } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 12,
    '&:first-of-type': {
      paddingLeft: 40,
      borderLeft: 'none',
    },
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  statsContainer: {
    marginTop: theme.spacing(6),
  },

  mainContainer: {
    position: 'relative',
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
  },

  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    '& .searchInput': {
      flexGrow: 1,
    },
  },

  tableBody: {
    '& .MuiTableRow-root': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      '&.styledTableRow': {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiTableCell-root': {
          padding: theme.spacing(2, 3),
          verticalAlign: 'middle',
        },
      },
      '&.isHiddenBottomBorder': {
        borderBottom: 'none',
      },
    },

    '& .MuiTableCell-root': {
      border: 'none',
      padding: theme.spacing(4, 3),
      verticalAlign: 'top',
      '&.cellWithIcon': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
      },
      '&:first-of-type': {
        paddingLeft: theme.spacing(11),
      },
      '& .cellContentWithIcon': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '&.withLargeGap': {
          gap: theme.spacing(2),
        },
      },
    },
  },

  blackIcon: {
    '& path': {
      fill: theme.palette.common.black,
    },
  },
}));

export { StyledTableCell, useStyles };
