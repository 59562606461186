import { useRef, useState, useEffect } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Chip as MaterialChip } from '@mui/material';
import { useStyles } from './Chip.styles.js';
import { Tooltip } from 'Components/components.js';

const CHIP_VARIANT = {
  outlined: 'outlined',
  filled: 'filled',
};

const CHIP_SIZE = {
  medium: 'medium',
  small: 'small',
};

const CHIP_COLOR = {
  default: 'default', // grey
  primary: 'primary', // white with divider color of border
  secondary: 'secondary', // orange
  error: 'error', // red
  info: 'info', // blue
  success: 'success', // without styles
  warning: 'warning', // yellow
};

const Chip = ({
  label = '',
  variant = CHIP_VARIANT.outlined,
  size = CHIP_SIZE.small,
  color = CHIP_COLOR.default,
  clickable = false,
  onClick,
  startIcon,
  endIcon,
  className = '',
  sx = {},
  maxWidth = null,
}) => {
  const ref = useRef();
  const [tooltipTitle, setTooltipTitle] = useState('');
  const classes = useStyles({ tooltipTitle, maxWidth });

  useEffect(() => {
    if (
      maxWidth &&
      ref?.current?.offsetWidth &&
      ref?.current?.offsetWidth >= maxWidth
    ) {
      setTooltipTitle(label);
    } else {
      setTooltipTitle('');
    }
  }, [label, maxWidth]);

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <MaterialChip
          label={label}
          variant={variant}
          size={size}
          color={color}
          className={clsx(classes.chip, 'textOverflow', className)}
          sx={sx}
          clickable={clickable}
          icon={startIcon || null}
          deleteIcon={endIcon || null}
          onClick={onClick || null}
          onDelete={endIcon ? () => {} : null}
          ref={ref}
        />
      </Tooltip>
    </>
  );
};

export { Chip, CHIP_VARIANT, CHIP_SIZE, CHIP_COLOR };
