import React from 'react';
import {
  useBqBillingData,
  useCallback,
  useEffect,
  useEmptySearchMessage,
  useGetBigQueryIncidentGroups,
  useLocation,
  useMemo,
  usePipelineStats,
  useState,
  useSyncTableToSearchParam,
} from 'hooks/hooks.js';
import { Col, Row } from 'react-bootstrap';
import { useStyles } from '../../Incidents.styles.js';
import { SourcesButton, TableDatasetSearch } from 'Components/components.js';
import { Dropdowns } from '../GlobalPageComponents/components.js';
import { PipelineIncidentCard } from './components/components.js';
import { useProjectTree, useUserInfo } from 'context/context.js';
import {
  getFullTableName,
  getObjectKeyByValue,
} from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  SEARCH_PARAMS,
  IncidentTabs,
  PIPELINES_METRIC_FILTER_OPTIONS,
  SHORT_INCIDENT_FILTER,
  INCIDENT_TYPE_GROUP,
  METRIC_FILTER_LABELS,
} from 'constants/constants.js';
import { formatIncidents } from 'Pages/Incidents/libs/utils/utils.js';
import {
  TABLE_ALERT_FILTER,
  INCIDENT_STATUS_SHORT_FILTER,
  PIPELINE_INCIDENT_TYPE_FILTER,
  PipelineTabDropdowns,
  INITIAL_TECHNOLOGY_OPTION,
  TECHNOLOGY_FILTER,
  SEARCH_PARAMS_CONFIG,
  EMPTY_SEARCH_MESSAGES,
} from 'Pages/Incidents/libs/constants/constants.js';
import {
  ALERT_FILTER_OPTIONS,
  BQ_BILLING_TYPE,
  PIPELINE_TYPE,
  PIPELINE_TYPE_DATA,
} from 'utils/constants.js';

const EXTSERVER_TENANT = 'extserver-1280';
const TAB_EVENT = `${AMPL_PAGE_EVENT.incidents} -> tab: ${IncidentTabs.PIPELINE} ->`;

const PipelineTab = ({
  startDate,
  endDate,
  handleChangeTotalIncidents,
  handleChangeTitleTotalIncidents,
  page,
  rowsPerPage,
  handleChangePage,
  onChangeOpenBackdrop,
  onChangeExistingDatePicker,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { currentBqBillingData } = useBqBillingData();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { user } = useUserInfo();
  const [tableInfo, setTableInfo] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [searchTable, setSearchTable] = useState(null);
  const { tableFromURL, tableInfoFromUrl, searchFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(
      tableInfo?.table,
      tableInfo?.dataset || dataset,
      searchTable,
      IncidentTabs.PIPELINE
    );
  const {
    setIsOpen,
    setSelectTable,
    setOnSelectTable,
    setSelectDataset,
    setOnSelectDataset,
  } = useProjectTree();

  const { pipelineStats } = usePipelineStats(startDate, endDate);

  const { emptySearchMessage } = useEmptySearchMessage({
    searchParamsConfig: SEARCH_PARAMS_CONFIG,
    emptySearchMessageConfig: {
      messages: EMPTY_SEARCH_MESSAGES,
    },
  });

  const isSlotsBillingData =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const isBytesBillingData =
    currentBqBillingData?.paymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  const pipelinesStatusFilterOptions = useMemo(() => {
    return PIPELINES_METRIC_FILTER_OPTIONS.map((item) => {
      if (currentBqBillingData?.paymentType && isBytesBillingData) {
        item.value = item.value.filter((valueOption) => {
          return valueOption !== INCIDENT_TYPE_GROUP.PIPELINE_SLOTS_MS;
        });
      } else if (currentBqBillingData?.paymentType && isSlotsBillingData) {
        item.value = item.value.filter((valueOption) => {
          return valueOption !== INCIDENT_TYPE_GROUP.PIPELINE_BILLED_BYTES;
        });
      }

      return item;
    }).filter((item) => {
      if (
        user?.info?.tenant === EXTSERVER_TENANT ||
        user?.info?.adminData?.currentTenant === EXTSERVER_TENANT
      ) {
        return item.label !== METRIC_FILTER_LABELS.DURATION;
      }

      return true;
    });
  }, [
    currentBqBillingData?.paymentType,
    isBytesBillingData,
    isSlotsBillingData,
    user?.info,
  ]);

  const technologyFilterOptions = [INITIAL_TECHNOLOGY_OPTION];
  technologyFilterOptions.push(
    ...pipelineStats.map((item) => {
      return {
        value: getObjectKeyByValue(PIPELINE_TYPE, item.pipelineType),
        label: PIPELINE_TYPE_DATA[item.pipelineType]?.title,
      };
    })
  );

  const [dropdownsValue, setDropdownsValue] = useState({
    // [PIPELINE_INCIDENT_TYPE_FILTER]: [pipelinesStatusFilterOptions[0]],
    [TECHNOLOGY_FILTER]: [technologyFilterOptions[0]],
    [TABLE_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[1], ALERT_FILTER_OPTIONS[2]],
    [INCIDENT_STATUS_SHORT_FILTER]: [SHORT_INCIDENT_FILTER[0]],
  });

  const incidentIdFromUrl = searchParams.get(SEARCH_PARAMS.ID);
  const incidentTypeFromUrl = searchParams.get(SEARCH_PARAMS.TYPE);
  const isAllowedRequest =
    currentBqBillingData?.paymentType !== undefined && pipelineStats.length > 0;

  const defaultIncidentIdAndType = useMemo(() => {
    return incidentIdFromUrl && incidentTypeFromUrl
      ? {
          id: incidentIdFromUrl,
          type: incidentTypeFromUrl,
        }
      : {
          id: null,
          type: null,
        };
  }, [incidentIdFromUrl, incidentTypeFromUrl]);

  const [incidentIdAndType, setIncidentIdAndType] = useState(
    defaultIncidentIdAndType
  );

  const tableInfoByIncident = (incident) => {
    return {
      uuid: incident.tableUuid,
      dataset: incident.dataset,
      table: incident.table,
      type: incident.tableType,
      alertType: incident.alertType,
    };
  };

  useEffect(() => {
    const id = searchParams.get(SEARCH_PARAMS.ID);
    const type = searchParams.get(SEARCH_PARAMS.TYPE);

    if (id && type) {
      setIncidentIdAndType({ id, type });
      onChangeExistingDatePicker(false);
    } else {
      setIncidentIdAndType({ id: null, type: null });
      onChangeExistingDatePicker(true);
    }
  }, [searchParams, onChangeExistingDatePicker]);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [tableInfo, dataset, searchTable, handleChangePage, startDate, endDate]);

  const {
    incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal,
    incidentsGroupPagination,
  } = useGetBigQueryIncidentGroups({
    startDate,
    endDate,
    page,
    rowsPerPage,
    dataset: tableInfo?.dataset || tableInfoFromUrl?.dataset,
    table: tableInfo?.table || tableInfoFromUrl?.table,
    searchTable: searchTable || searchFromUrl,
    // incidentGroupsTypes: dropdownsValue[PIPELINE_INCIDENT_TYPE_FILTER],
    incidentGroupsTypes: [pipelinesStatusFilterOptions[0]],
    incidentGroupsAlert: dropdownsValue[TABLE_ALERT_FILTER],
    incidentGroupsStatuses: dropdownsValue[INCIDENT_STATUS_SHORT_FILTER],
    pipelineType: dropdownsValue[TECHNOLOGY_FILTER][0].value,
    incidentIdAndType,
    enabled: isAllowedRequest,
  });

  useEffect(() => {
    handleChangeTitleTotalIncidents(incidentsTotal);
    handleChangeTotalIncidents(incidentsGroupPagination);
  }, [
    handleChangeTitleTotalIncidents,
    handleChangeTotalIncidents,
    incidentsGroupPagination,
    incidentsTotal,
  ]);

  const formattedData = useMemo(() => {
    if (!incidentGroups.length) {
      return {};
    }
    return formatIncidents(incidentGroups);
  }, [incidentGroups]);

  const isSearching = useMemo(() => {
    return Boolean(
      isAllowedRequest &&
        !incidentGroups.length &&
        !isIncidentGroupsFetching &&
        emptySearchMessage.length
    );
  }, [
    emptySearchMessage.length,
    incidentGroups.length,
    isAllowedRequest,
    isIncidentGroupsFetching,
  ]);

  useEffect(() => {
    if (tableFromURL === null) {
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);
      setDataset(null);
    }
  }, [
    tableFromURL,
    setTableInfo,
    setSelectTable,
    setDataset,
    setSelectDataset,
  ]);

  useEffect(() => {
    if (searchFromUrl === null) {
      setSearchTable(null);
    }
  }, [searchFromUrl]);

  useEffect(() => {
    onChangeOpenBackdrop(isIncidentGroupsFetching);
  }, [isIncidentGroupsFetching, onChangeOpenBackdrop]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const onTableChange = useCallback(
    (value) => {
      setTableInfo(value);
      setSelectTable(value);
      setDataset(null);
      setSelectDataset(null);

      if (value !== null) {
        amplEvent(
          `${TAB_EVENT} selected table: ${getFullTableName(
            value?.table,
            value?.dataset
          )}`
        );
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onDatasetChange = useCallback(
    (value) => {
      setDataset(value);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(value);

      if (value !== null) {
        amplEvent(`${TAB_EVENT} selected dataset: ${value}`);
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onTableSearch = useCallback(
    (value) => {
      setSearchTable(value);
      setDataset(null);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);

      if (value.length) {
        amplEvent(`${TAB_EVENT} search for: ${value}`);
      }

      if (!value) clearSearchParams();
    },
    [setSearchTable, setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  useEffect(() => {
    if (onTableChange !== undefined) {
      setOnSelectTable({ onChange: onTableChange });
    }
  }, [onTableChange, setOnSelectTable]);

  useEffect(() => {
    if (onDatasetChange !== undefined) {
      setOnSelectDataset({ onChange: onDatasetChange });
    }
  }, [onDatasetChange, setOnSelectDataset]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  return (
    <>
      {(!incidentIdAndType.id || !incidentIdAndType.type) && (
        <>
          <Dropdowns
            existingDropdown={PipelineTabDropdowns}
            onChangeDropdownsValue={handleChangeDropdownsValue}
            defaultPipelineIncidentTypeOptions={pipelinesStatusFilterOptions}
            technologyFilterOptions={technologyFilterOptions}
          />

          <div className={classes.searchContainer}>
            <SourcesButton onClick={handleProjectTree} />
            <TableDatasetSearch
              optionLabel='Incidents for:'
              onTableChange={onTableChange}
              onDatasetChange={onDatasetChange}
              onSearch={onTableSearch}
              defaultDataset={tableInfoFromUrl?.dataset}
              defaultTable={tableInfoFromUrl?.table}
              defaultSearchValue={searchFromUrl}
              className='tableSearch'
            />
          </div>
        </>
      )}

      {isSearching && (
        <p className='txt-mainDark-16-500 text-center'>{emptySearchMessage}</p>
      )}

      {Object.keys(formattedData).map((date) => (
        <Row key={date}>
          <Col xs={12}>
            <p className='txt-mainDark-20-700 mb-3'>{date}</p>
          </Col>
          {formattedData[date].map((incident) => (
            <Col key={incident.groupKey} xs={12} className='mb-3'>
              <PipelineIncidentCard
                groupIncident={incident}
                startDate={startDate}
                endDate={endDate}
                incidentIdAndType={incidentIdAndType}
                incidentStatuses={dropdownsValue[INCIDENT_STATUS_SHORT_FILTER]}
                tableInfo={tableInfoByIncident(incident)}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export { PipelineTab };
