const SELECTED_EMAIL = 'selectedEmail';
const SEARCH_EMAIL = 'searchEmail';
const SELECTED_TABLE = 'selectedTable';
const SEARCH_TABLE = 'searchTable';
const SELECTED_DESTINATION_TABLE = 'selectedDestinationTable';
const SEARCH_DESTINATION_TABLE = 'searchDestinationTable';
const SELECTED_TAGS = 'selectedTags';
const SELECTED_LABEL = 'selectedLabel';
const SEARCH_LABEL = 'searchLabel';

const DefaultSearchValues = {
  [SELECTED_EMAIL]: '',
  [SEARCH_EMAIL]: '',
  [SELECTED_TABLE]: null,
  [SEARCH_TABLE]: '',
  [SELECTED_DESTINATION_TABLE]: null,
  [SEARCH_DESTINATION_TABLE]: '',
  [SELECTED_TAGS]: [],
  [SELECTED_LABEL]: null,
  [SEARCH_LABEL]: '',
};

const DEFAULT_SEARCH_MESSAGE = 'No costs found associated to this';

const SEARCH_PARAMS_CONFIG = Object.keys(DefaultSearchValues);

const PARAM_GROUPS = {
  email: [SELECTED_EMAIL, SEARCH_EMAIL],
  table: [SELECTED_TABLE, SEARCH_TABLE],
  'destination table': [SELECTED_DESTINATION_TABLE, SEARCH_DESTINATION_TABLE],
  'label/tag': [SELECTED_TAGS],
  label: [SELECTED_LABEL, SEARCH_LABEL],
};

const PARAM_LABELS = Object.entries(PARAM_GROUPS).reduce(
  (acc, [label, params]) => {
    params.forEach((param) => {
      acc[param] = label;
    });
    return acc;
  },
  {}
);

const EMPTY_SEARCH_MESSAGES = SEARCH_PARAMS_CONFIG.reduce((acc, param) => {
  acc[param] = `${DEFAULT_SEARCH_MESSAGE} ${PARAM_LABELS[param]}`;
  return acc;
}, {});

export {
  SELECTED_EMAIL,
  SEARCH_EMAIL,
  SELECTED_TABLE,
  SEARCH_TABLE,
  SELECTED_DESTINATION_TABLE,
  SEARCH_DESTINATION_TABLE,
  SELECTED_TAGS,
  SELECTED_LABEL,
  SEARCH_LABEL,
  DefaultSearchValues,
  SEARCH_PARAMS_CONFIG,
  EMPTY_SEARCH_MESSAGES,
  DEFAULT_SEARCH_MESSAGE,
};
