import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { TableCell, TableRow } from '@mui/material';
import { OverflownText } from 'Components/components.js';
import {
  CostLabel,
  JobTagsTableCell,
  SourceTableCell,
} from 'Pages/Pipelines/components/components.js';
import { bytesToSize, millisecondsToTimeSlots } from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';

const { RESERVATION_WIDTH, PRINCIPAL_EMAIL_WIDTH } = PipelineTableCellStyles;

const ResourceTableSourceRaw = ({ sourceData }) => {
  const {
    tables,
    sourceUri,
    principalEmail,
    jobTags,
    reservation,
    sourceProject,
    totalBytes,
    totalSlotsMs,
    costs,
    paymentType,
    routines,
  } = sourceData;

  const totalData =
    paymentType === BQ_BILLING_TYPE.ON_DEMANDS
      ? bytesToSize(totalBytes)
      : millisecondsToTimeSlots(totalSlotsMs);

  const sources = useMemo(() => {
    return [...tables, ...routines];
  }, [routines, tables]);

  return (
    <TableRow style={{ cursor: 'default' }}>
      <SourceTableCell sourceData={sources} sourceUri={sourceUri} />

      <TableCell>
        <OverflownText title={principalEmail} maxWidth={PRINCIPAL_EMAIL_WIDTH}>
          {principalEmail}
        </OverflownText>
      </TableCell>

      <JobTagsTableCell jobTags={jobTags} />

      <TableCell align='left' className='nowrapText'>
        {reservation?.length ? (
          <OverflownText title={reservation} maxWidth={RESERVATION_WIDTH}>
            {reservation}
          </OverflownText>
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        {sourceProject}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        {totalData.length ? (
          totalData
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        <CostLabel value={costs} />
      </TableCell>
    </TableRow>
  );
};

export { ResourceTableSourceRaw };
