import moment from 'moment';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Widget from 'Components/Widget/Widget.js';
import { DATE_FORMAT } from 'constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
  },

  label: {
    width: 120,
    marginBottom: theme.spacing(1),
  },
}));

const RoutineInfoWidget = ({ routineDetails }) => {
  const classes = useStyles();
  const {
    project,
    datasetName,
    creatineTime,
    lastModifiedTime,
    description,
    name,
  } = routineDetails;

  const routineInfo = [
    { label: 'Name', value: name },
    { label: 'Dataset', value: datasetName },
    { label: 'Project', value: project },
    {
      label: 'Created',
      value: moment(creatineTime).utc().format(DATE_FORMAT.monthYearTime),
    },
    {
      label: 'Last modifier',
      value: moment(lastModifiedTime).utc().format(DATE_FORMAT.monthYearTime),
    },
    { label: 'Description', value: description },
  ];

  return (
    <Widget
      label='Persistent function info'
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <div>
        {routineInfo.map((item) => (
          <div key={item.label} className={classes.container}>
            <div className={clsx(classes.label, 'txt-grey-13-500')}>
              {item.label}
            </div>
            <div>
              {item.value || <span className='txt-grey-13-500'>&mdash;</span>}
            </div>
          </div>
        ))}
      </div>
    </Widget>
  );
};

export { RoutineInfoWidget };
