import React from 'react';
import { TableCell } from '@mui/material';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import { OverflownText } from 'Components/components.js';
import { getFullTableName, highlightText } from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import { REFERENCED_TYPE } from 'constants/constants.js';
import { ReactComponent as SourceUriIcon } from 'assets/img/icons/lineageIcons/node-icons/source-uri.svg';

const { DESTINATION_TABLE_NAME_WIDTH } = PipelineTableCellStyles;

const SourceTableCell = ({
  sourceData,
  sourceUri,
  selectedTable = null,
  searchValue = '',
}) => {
  const classes = useStyles();

  return (
    <TableCell>
      {sourceData.map((source, i) => {
        const sourceName = getFullTableName(source.name, source.dataset);
        const IconComponent = REFERENCED_TYPE[source.type].icon || null;

        return (
          <div key={sourceName + i} className='cellContentWithIcon'>
            <IconComponent className={classes.blackIcon} />

            <OverflownText
              title={sourceName}
              maxWidth={DESTINATION_TABLE_NAME_WIDTH}
            >
              {highlightText(sourceName, selectedTable, searchValue)}
            </OverflownText>
          </div>
        );
      })}

      {Boolean(sourceUri) && (
        <div className='cellContentWithIcon'>
          <SourceUriIcon className={classes.blackIcon} />

          <OverflownText
            title={sourceUri}
            maxWidth={DESTINATION_TABLE_NAME_WIDTH}
          >
            {highlightText(sourceUri)}
          </OverflownText>
        </div>
      )}
    </TableCell>
  );
};

export { SourceTableCell };
